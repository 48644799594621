import React, { Component } from 'react';
import './App.css';
import Header from './header/header';
import SideMenu from './sidemenu/sidemenu';
import Home from './home/home';
import SwaggerUIComponent from './swaggerUIComponent/swaggerUIComponent';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.system = null;
  }

  render() {
    return (
      <>
        <SideMenu />
        <header>
          <Header />
        </header>
        <main>
          <Home />
          <hr />
          <SwaggerUIComponent />
        </main>
      </>
    );
  }
}

export default App;
