import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import OasisComponent from '../OasisComponent';

export const SYNC_TITLE = 'api.doc.v3.sync.title';
export const SYNC_DESCRIPTION = 'api.doc.v3.sync.description';
export const PHOTOS_TITLE = 'api.doc.v3.photos.title';
export const PHOTOS_DESCRIPTION = 'api.doc.v3.photos.description';

class HomeDisclaimer extends OasisComponent {
  constructor() {
    super([
      SYNC_TITLE,
      SYNC_DESCRIPTION,
      PHOTOS_TITLE,
      PHOTOS_DESCRIPTION,
    ]);
  }

  render() {
    return (
      <>
        <div id="sync">
          <h2>{this.i18n(SYNC_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(SYNC_DESCRIPTION))}
          </>
        </div>
        <div id="photos-api">
          <h2>{this.i18n(PHOTOS_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(PHOTOS_DESCRIPTION))}
          </>
        </div>
      </>
    );
  }
}

HomeDisclaimer.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeDisclaimer);
