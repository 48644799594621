const FALLBACK_LANGUAGE = 'en-US';

const languages = ['en-US', 'es-ES', 'pt-BR'];

export function getUserDefinedLanguage() {
  return navigator.language || navigator.userLanguage || FALLBACK_LANGUAGE;
}

export function getDefaultLanguage() {
  const userDefinedLanguage = getUserDefinedLanguage();
  const language = languages[userDefinedLanguage];
  if (language) {
    return userDefinedLanguage;
  }

  const userDefinedLanguagePrefix = userDefinedLanguage.split('-')[0];
  for (const lang of languages) {
    const languagePrefix = lang.split('-')[0];
    if (userDefinedLanguagePrefix === languagePrefix) {
      return lang;
    }
  }
  return FALLBACK_LANGUAGE;
}
