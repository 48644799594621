import React from 'react';
import PropTypes from 'prop-types';
import SwaggerUI from 'swagger-ui';
import { connect } from 'react-redux';

import './swaggerUIComponent.css';
import './swagger-override.css';
import { updateLanguage } from '../redux/actions/language-actions';
import { TRANSLATIONS_OUTPUT_DIR, BACKEND_URL as host } from '../../core/env';
import OasisComponent from '../OasisComponent';
import OperationsFilterPlugin from './plugins/OperationsFilterPlugin';

export const REFERENCE_TITLE = 'api.doc.v3.reference.title';

class SwaggerUIComponent extends OasisComponent {
  static propTypes = {
    language: PropTypes.string.isRequired,
  };

  constructor() {
    super([
      REFERENCE_TITLE,
    ]);
    this.system = null;
    this.SwaggerUIComponent = null;
  }

  componentDidMount() {
    super.componentDidMount();
    this.createSwaggerUIComponent();
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    const { language } = this.props;
    if (prevProps != null && prevProps.language !== language) {
      this.createSwaggerUIComponent();
    }
  }

  languageSelector = () => {
    const dir = `${TRANSLATIONS_OUTPUT_DIR}/schemas`;
    let { language } = this.props;
    if (!language) {
      language = 'pt-BR';
    }
    return `${host}/${dir}/${language}.json`;
  }

  createSwaggerUIComponent() {
    const ui = SwaggerUI({
      url: this.languageSelector(),
      docExpansion: 'list',
      supportedSubmitMethods: [],
      explorer: true,
      plugins: [OperationsFilterPlugin],
      layout: 'OperationsFilterLayout',
    });
    this.system = ui;
    this.SwaggerUIComponent = ui.getComponent('App', 'root');
    this.forceUpdate();
  }

  render() {
    return this.SwaggerUIComponent ? (
      <div>
        <h2 id="api-reference" className="title-highlight">{this.i18n(REFERENCE_TITLE)}</h2>
        <this.SwaggerUIComponent />
      </div>
    ) : null;
  }
}


const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps, { updateLanguage })(SwaggerUIComponent);
