import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import OasisComponent from '../OasisComponent';

export const INTRO_TITLE = 'api.doc.v3.intro.title';
export const INTRO_DESCRIPTION = 'api.doc.v3.intro.description';

class HomeIntroduction extends OasisComponent {
  constructor() {
    super([
      INTRO_TITLE,
      INTRO_DESCRIPTION,
    ]);
  }

  render() {
    return (
      <div id="intro">
        <h2>{this.i18n(INTRO_TITLE)}</h2>
        <div>
          {ReactHtmlParser(this.i18n(INTRO_DESCRIPTION))}
        </div>
      </div>
    );
  }
}

HomeIntroduction.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeIntroduction);
