const dotenv = require('dotenv');

dotenv.config();

const DEFAULT_INPUT_DIR = process.env.INPUT_DIR || './input';
const FALLBACK_LOCALE = process.env.FALLBACK_LOCALE || 'pt-BR';
const LOCALES_INPUT_DIR = `${DEFAULT_INPUT_DIR}/locales`;
const SCHEMA_INPUT_DIR = `${DEFAULT_INPUT_DIR}/schemas`;
const LOCALES_PATH = `${LOCALES_INPUT_DIR}/locales.json`;
const TRANSLATIONS_OUTPUT_DIR = process.env.TRANSLATIONS_OUTPUT_DIR || './translations';
const PORT = process.env.PORT || 5000;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || `http://localhost:${PORT}`;

const { INVOLVES_VAULT_ADDRESS } = process.env;
const { INVOLVES_VAULT_TOKEN } = process.env;
const AUTHENTICATE_THROUGH_VAULT = !!INVOLVES_VAULT_ADDRESS;
const INVOLVES_STAGE_ADDRESS = process.env.INVOLVES_STAGE_ADDRESS || 'http://localhost:8080/agile-web';
const INVOLVES_STAGE_AUTHORIZATION = process.env.INVOLVES_STAGE_AUTHORIZATION || 'aW52b2x2ZXM6bm92YXNlbmhhcWEh';

const { LOCO_API_KEY } = process.env;

module.exports = {
  FALLBACK_LOCALE,
  LOCALES_INPUT_DIR,
  SCHEMA_INPUT_DIR,
  LOCALES_PATH,
  TRANSLATIONS_OUTPUT_DIR,
  PORT,
  INVOLVES_VAULT_ADDRESS,
  INVOLVES_VAULT_TOKEN,
  AUTHENTICATE_THROUGH_VAULT,
  INVOLVES_STAGE_ADDRESS,
  INVOLVES_STAGE_AUTHORIZATION,
  BACKEND_URL,
  LOCO_API_KEY,
};
