import React from 'react';

import HomeIntroduction from './home-introduction';
import HomeLimitations from './home-limitations';
import HomeCharges from './home-charges';
import HomeSetup from './home-setup';
import HomeInfo from './home-info';
import HomeDisclaimer from './home-disclaimer';

export default function Home() {
  return (
    <div className="home">
      <HomeIntroduction />
      <hr />
      <HomeLimitations />
      <hr />
      <HomeCharges />
      <hr />
      <HomeSetup />
      <hr />
      <HomeInfo />
      <hr />
      <HomeDisclaimer />
    </div>
  );
}
