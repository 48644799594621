import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import OasisComponent from '../OasisComponent';

export const LIMITATIONS_TITLE = 'api.doc.v3.limitations.title';
export const LIMITATIONS_DESCRIPTION = 'api.doc.v3.limitations.description';

class HomeLimitations extends OasisComponent {
  constructor() {
    super([
      LIMITATIONS_TITLE,
      LIMITATIONS_DESCRIPTION,
    ]);
  }

  render() {
    return (
      <div id="limitations">
        <h2>{this.i18n(LIMITATIONS_TITLE)}</h2>
        <div>
          {ReactHtmlParser(this.i18n(LIMITATIONS_DESCRIPTION))}
        </div>
      </div>
    );
  }
}

HomeLimitations.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeLimitations);
