import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import OasisComponent from '../OasisComponent';

export const HTTP_METHODS_TITLE = 'api.doc.v3.http-methods.title';
export const HTTP_METHODS_DESCRIPTION = 'api.doc.v3.http-methods.description';
export const ERROR_HANDLING_TITLE = 'api.doc.v3.error-handling.title';
export const ERROR_HANDLING_DESCRIPTION = 'api.doc.v3.error-handling.description';
export const HATEOAS_TITLE = 'api.doc.v3.hateoas.title';
export const HATEOAS_DESCRIPTION = 'api.doc.v3.hateoas.description';
export const PAGINATION_TITLE = 'api.doc.v3.pagination.title';
export const PAGINATION_DESCRIPTION = 'api.doc.v3.pagination.description';

class HomeInfo extends OasisComponent {
  constructor() {
    super([
      HTTP_METHODS_TITLE,
      HTTP_METHODS_DESCRIPTION,
      ERROR_HANDLING_TITLE,
      ERROR_HANDLING_DESCRIPTION,
      HATEOAS_TITLE,
      HATEOAS_DESCRIPTION,
      PAGINATION_TITLE,
      PAGINATION_DESCRIPTION,
    ]);
  }

  render() {
    return (
      <>
        <div id="http-methods">
          <h2>{this.i18n(HTTP_METHODS_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(HTTP_METHODS_DESCRIPTION))}
          </>
        </div>
        <div id="error-handling">
          <h2>{this.i18n(ERROR_HANDLING_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(ERROR_HANDLING_DESCRIPTION))}
          </>
        </div>
        <div id="hateoas">
          <h2>{this.i18n(HATEOAS_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(HATEOAS_DESCRIPTION))}
          </>
        </div>
        <div id="pagination">
          <h2>{this.i18n(PAGINATION_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(PAGINATION_DESCRIPTION))}
          </>
        </div>
      </>
    );
  }
}

HomeInfo.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeInfo);
