import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import './home-setup.css';
import image1 from '../images/environment-guide/image1.png';
import image2 from '../images/environment-guide/image2.png';
import image3 from '../images/environment-guide/image3.png';
import image4 from '../images/environment-guide/image4.png';
import OasisComponent from '../OasisComponent';
import getCurrentApiV3Version from '../involves-stage/api-v3.service';

export const HTTP_HEADERS_TITLE = 'api.doc.v3.http-headers.title';
export const HTTP_HEADERS_DESCRIPTION = 'api.doc.v3.http-headers.description';
export const API_CURRENT_VERSION_DESCRIPTION = 'api.doc.v3.http-headers.current-version.description';
export const AUTH_TITLE = 'api.doc.v3.auth.title';
export const AUTH_DESCRIPTION = 'api.doc.v3.auth.description';

class HomeSetup extends OasisComponent {
  constructor() {
    super([
      HTTP_HEADERS_TITLE,
      HTTP_HEADERS_DESCRIPTION,
      AUTH_TITLE,
      AUTH_DESCRIPTION,
      API_CURRENT_VERSION_DESCRIPTION,
    ]);
  }

  getLatestVersion = async () => {
    try {
      const currentApiVersion = await getCurrentApiV3Version();
      return currentApiVersion.version;
    } catch (e) {
      console.log('Couldn\'t fetch API version', e);
      return false;
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    const currentApiVersion = await this.getLatestVersion();
    this.setState({ currentApiVersion });
  }

  render() {
    return (
      <>
        <div id="setup">
          <h2>{this.i18n(HTTP_HEADERS_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(HTTP_HEADERS_DESCRIPTION))}
            {
            this.state.currentApiVersion && (
              <center>
                <p>
                  {this.i18n(API_CURRENT_VERSION_DESCRIPTION)}
                  :&nbsp;
                  <strong>{this.state.currentApiVersion}</strong>
                </p>
              </center>
            )
          }
          </>
        </div>
        <div id="auth">
          <h2>{this.i18n(AUTH_TITLE)}</h2>
          <>
            {ReactHtmlParser(this.i18n(AUTH_DESCRIPTION))}
            <div className="env-selection-guide">
              <div className="row">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
              </div>
              <img src={image3} alt="" />
              <img src={image4} alt="" />
            </div>
          </>
        </div>
      </>
    );
  }
}

HomeSetup.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeSetup);
