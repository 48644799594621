import { getDefaultLanguage } from '../../i18n/language.service';

const initialState = {
  language: getDefaultLanguage(),
};

export default function (state = initialState, action) {
  console.log('action', action);
  switch (action.type) {
    case 'UPDATE_LANGUAGE': {
      const { language } = action.payload;
      return {
        ...state,
        language,
      };
    }
    default:
      return state;
  }
}
