import React from 'react';
import PropTypes from 'prop-types';

import i18n from './i18n/i18n.service';

export default class OasisComponent extends React.Component {
  constructor(translationKeys) {
    super();
    this.state = { translations: {} };
    this.translationKeys = translationKeys;
  }

  componentDidMount() {
    this.translate();
  }

  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (prevProps != null && prevProps.language !== language) {
      this.translate();
    }
  }

  async translate() {
    const { language } = this.props;

    const translations = {};
    this.translationKeys.forEach(key => {
      i18n.translate(language, key)
        .then(translation => {
          translations[key] = translation;
          this.setState({ translations });
        });
    });
  }

  i18n(key) {
    const { translations } = this.state;
    return translations[key];
  }
}

OasisComponent.propTypes = {
  language: PropTypes.string.isRequired,
};
