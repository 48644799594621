import React from 'react';
import { connect } from 'react-redux';
import { SideNav, Nav } from 'react-sidenav';
import { Link } from 'react-scroll';

import './sidemenu.css';
import logoFull from '../images/logo-full.png';
import OasisComponent from '../OasisComponent';

import { INTRO_TITLE } from '../home/home-introduction';
import { LIMITATIONS_TITLE } from '../home/home-limitations';
import { CHARGES_TITLE } from '../home/home-charges';
import { HTTP_HEADERS_TITLE, AUTH_TITLE } from '../home/home-setup';
import {
  HTTP_METHODS_TITLE,
  ERROR_HANDLING_TITLE,
  HATEOAS_TITLE,
  PAGINATION_TITLE,
} from '../home/home-info';
import { SYNC_TITLE, PHOTOS_TITLE } from '../home/home-disclaimer';
import { REFERENCE_TITLE } from '../swaggerUIComponent/swaggerUIComponent';

const Item = ({ id, children }) => (
  <Nav id={id}>
    <Link
      to={id}
      duration={700}
      offset={-80}
      activeClass="active"
      isDynamic
      smooth
      spy
    >
      {children}
    </Link>
  </Nav>
);

class SideMenu extends OasisComponent {
  constructor() {
    super([
      INTRO_TITLE,
      LIMITATIONS_TITLE,
      CHARGES_TITLE,
      HTTP_HEADERS_TITLE,
      AUTH_TITLE,
      HTTP_METHODS_TITLE,
      ERROR_HANDLING_TITLE,
      HATEOAS_TITLE,
      PAGINATION_TITLE,
      SYNC_TITLE,
      PHOTOS_TITLE,
      REFERENCE_TITLE,
    ]);
  }

   onSelection = selected => {
     console.log(selected);
   };

   render() {
     return (
       <nav>
         <div className="involves-logo-container">
           <img src={logoFull} alt="" />
         </div>
         <SideNav onSelection={this.onSelection}>
           <Item id="intro">{this.i18n(INTRO_TITLE)}</Item>
           <Item id="limitations">{this.i18n(LIMITATIONS_TITLE)}</Item>
           <Item id="charges">{this.i18n(CHARGES_TITLE)}</Item>
           <Item id="setup">{this.i18n(HTTP_HEADERS_TITLE)}</Item>
           <Item id="auth">{this.i18n(AUTH_TITLE)}</Item>
           <Item id="http-methods">{this.i18n(HTTP_METHODS_TITLE)}</Item>
           <Item id="error-handling">{this.i18n(ERROR_HANDLING_TITLE)}</Item>
           <Item id="hateoas">{this.i18n(HATEOAS_TITLE)}</Item>
           <Item id="pagination">{this.i18n(PAGINATION_TITLE)}</Item>
           <Item id="sync">{this.i18n(SYNC_TITLE)}</Item>
           <Item id="photos-api">{this.i18n(PHOTOS_TITLE)}</Item>
           <Item id="api-reference">{this.i18n(REFERENCE_TITLE)}</Item>
         </SideNav>
       </nav>
     );
   }
}

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(SideMenu);
