import React from 'react';
import PropTypes from 'prop-types';

import OasisComponent from '../../OasisComponent';

const FILTER_INPUT_PLACEHOLDER_DESCRIPTION = 'api.doc.v3.reference.filter-input-placeholder.description';

class OperationsFilterLayout extends OasisComponent {
  constructor() {
    super([
      FILTER_INPUT_PLACEHOLDER_DESCRIPTION,
    ]);
  }

  onFilterChange = e => {
    const { target: { value } } = e;
    const { layoutActions } = this.props;
    layoutActions.updateFilter(value);
  }

  render() {
    const { getComponent } = this.props;
    const BaseLayout = getComponent('BaseLayout', true);
    const Col = getComponent('Col', true);
    return (
      <div>
        <div className="swagger-ui">
          <div className="filter-container override-default">
            <Col className="filter wrapper" mobile={12}>
              <input
                className="operation-filter-input"
                placeholder={this.i18n(FILTER_INPUT_PLACEHOLDER_DESCRIPTION)}
                type="text"
                onChange={this.onFilterChange}
              />
            </Col>
          </div>
        </div>
        <BaseLayout />
      </div>
    );
  }
}

OperationsFilterLayout.propTypes = {
  getComponent: PropTypes.func.isRequired,
  layoutActions: PropTypes.shape.isRequired,
};

export default () => ({
  components: {
    OperationsFilterLayout,
  },
  fn: {
    opsFilter: (taggedOps, phrase) => taggedOps
      .map(section => section.set('operations', section.get('operations')
        .filter(operation => {
          const method = operation.get('method').toLowerCase();
          const path = operation.get('path').toLowerCase().split('/')
            .filter(part => !part || !part.startsWith('{'));
          const search = phrase.toLowerCase();
          return method.indexOf(search) !== -1 || path.some(part => part.indexOf(search) !== -1);
        })))
      .filter(section => !section.get('operations').isEmpty()),
  },
});
