import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './header.css';
import { updateLanguage } from '../redux/actions/language-actions';
import OasisComponent from '../OasisComponent';
import { getDefaultLanguage } from '../i18n/language.service';

const API_TITLE = 'api.doc.v3.page.title';

class Header extends OasisComponent {
  constructor(props) {
    super([
      API_TITLE,
    ]);
    this.updateLanguage = props.updateLanguage;

    this.DEFAULT_USER_LOCALE = getDefaultLanguage();
  }

  onChangeSelect = element => {
    this.props.updateLanguage(element.target.value);
  }

  render() {
    return (
      <>
        <div className="topbar">
          <select defaultValue={this.DEFAULT_USER_LOCALE} onChange={this.onChangeSelect}>
            <option value="pt-BR">Português</option>
            <option value="en-US">English</option>
            <option value="es-ES">Español</option>
          </select>
        </div>
        <h1>{this.i18n(API_TITLE)}</h1>
      </>
    );
  }
}

Header.propTypes = {
  language: PropTypes.string.isRequired,
  updateLanguage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateLanguage,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
