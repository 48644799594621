const { BACKEND_URL: host, FALLBACK_LOCALE } = require('../../core/env');

async function fetchTranslations() {
  const localesAddress = `${host}/locales/locales.json`;
  const response = await fetch(localesAddress);
  return response.json();
}

let translations;
const translationsPromise = fetchTranslations()
  .then(result => { translations = result; });

class I18nService {
  getTranslation = (language, key) => {
    if (translations[language] && translations[language][key]) {
      return translations[language][key];
    }
    return null;
  }

  async translate(language, key) {
    return translationsPromise
      .then(() => this.getTranslation(language, key)
        || this.getTranslation(FALLBACK_LOCALE, key)
        || key);
  }
}

export default new I18nService();
