import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import OasisComponent from '../OasisComponent';

export const CHARGES_TITLE = 'api.doc.v3.charges.title';
export const CHARGES_DESCRIPTION = 'api.doc.v3.charges.description';

class HomeCharges extends OasisComponent {
  constructor() {
    super([
      CHARGES_TITLE,
      CHARGES_DESCRIPTION,
    ]);
  }

  render() {
    return (
      <div id="charges">
        <h2>{this.i18n(CHARGES_TITLE)}</h2>
        <div>
          {ReactHtmlParser(this.i18n(CHARGES_DESCRIPTION))}
        </div>
      </div>
    );
  }
}

HomeCharges.propTypes = {
  language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { language } = state.languageReducer;
  return { language };
};

export default connect(mapStateToProps)(HomeCharges);
